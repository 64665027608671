/* Navbar.css */
.navbar {
    position: fixed;
    display: flex;
    justify-content: space-between;
    z-index: 50;
    height: 55px;
    opacity: 1;
    width: 100%;
    box-sizing: border-box;
    transition: background-color 0.5s;
  }

  .logo img {
    height: 60px;
    width: 65px;
  }
  
  .logo, .navItems {
    display: flex;
    align-items: center;
  }
  
/*
=====================================================================
  Theme Changer 
=====================================================================
*/
.themeDropdownButton {
  padding: 10px;
  padding-left: 5px;
  height: 55px;
  padding-right: 35px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: background-color 0.5s;
}

.themeDropdown {
  position: relative;
  display: inline-block;
}

/* make a triangle that points down*/
.themeDropdown::after {
  content: "";
  position: absolute;
  top: 50%;
  margin-left: -27px;
  margin-top: -4px; /* Adjust margin to position the triangle correctly */
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 12px solid;
}

/* make a triangle that points up */
.themeDropdown.open::after {
  border-top: none;
  border-bottom: 12px solid; /* Reverse the triangle */
}

.themeDropdownContainer {
  display: none;
  position: absolute;
  width: 70%;
  z-index: 1;
  display: block;
  right: 0;
}

.themeButton {
  width: 100%;
  height: 50px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: background-color 0.5s;
}

/*
=====================================================================
  Language Button
=====================================================================
*/
.buttonsBox {
  display: flex;
  align-items: center;
}

.translationButtonLaptop,
.translationButtonPhone {
  font-size: 14px;
  letter-spacing: 0.5px;
}

.translationButtonLaptop {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  padding: 0 15px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease;
  border: 1px solid rgba(255,255,255,0.1);
  background: rgba(255,255,255,0.05);
}

.translationButtonLaptop:hover {
  background: rgba(255,255,255,0.1);
  transform: scale(1.05);
}

.translationButtonPhone {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 60px;
  border-radius: 8px;
  font-weight: 500;
  margin-right: 10px;
  border: 1px solid rgba(255,255,255,0.1);
  background: rgba(255,255,255,0.05);
}

/* 不同主题的适配 */
/* Pro主题 */
.Pro .translationButtonLaptop,
.Pro .translationButtonPhone {
  color: #f0f0f0;
  background: rgba(0, 98, 255, 0.15);
  border: 1px solid rgba(0, 98, 255, 0.3);
}

.Pro .translationButtonLaptop:hover,
.Pro .translationButtonPhone:hover {
  background: rgba(0, 98, 255, 0.25);
  transform: scale(1.05);
}

/* UNSW主题 */
.UNSW .translationButtonLaptop,
.UNSW .translationButtonPhone {
  color: #ffd700;
  background: rgba(0, 53, 128, 0.15);
  border: 1px solid rgba(0, 53, 128, 0.3);
}

.UNSW .translationButtonLaptop:hover,
.UNSW .translationButtonPhone:hover {
  background: rgba(0, 53, 128, 0.25);
  transform: scale(1.05);
}

/* UMelb主题 */
.UMelb .translationButtonLaptop,
.UMelb .translationButtonPhone {
  color: #002855;
  background: rgba(255, 215, 0, 0.15);
  border: 1px solid rgba(255, 215, 0, 0.3);
}

.UMelb .translationButtonLaptop:hover,
.UMelb .translationButtonPhone:hover {
  background: rgba(255, 215, 0, 0.25);
  transform: scale(1.05);
}

/* 
=====================================================================
  Nav Button 
=====================================================================
*/

.tabs {
  display: flex;
  align-items: center;
}

.tabs .input {
  display: none;
  visibility: hidden;
}

.buttons {
  display: flex;
  position: relative;
  gap: 25px;
  padding: 15px 18px;
  overflow: hidden;
  transition: background-color 0.5s;
}

.buttons .label {
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: 0.5s;
  opacity: 0.5;

}

.buttons .label:hover,
.tabs input:nth-child(1):checked ~ .buttons .label:nth-child(1),
.tabs input:nth-child(2):checked ~ .buttons .label:nth-child(2),
.tabs input:nth-child(3):checked ~ .buttons .label:nth-child(3),
.tabs input:nth-child(4):checked ~ .buttons .label:nth-child(4) {
  opacity: 1;
}

.menuButton {
  display: none;
}

@media screen and (max-width: 760px){
  /* dont account for desktop scroll bar */
  .navbar {
    width: 100vw;
  }

  .themeButton {
    padding-left: 0%;
    padding-right: 0%;
  }
  .themeDropdownButton {
    padding-right: 30px;
  }

  .translationButtonPhone {
    display: flex; 
    margin-top: auto;
    margin-bottom: auto;
    height: 30px;
    cursor: pointer;
  }

  .translationButtonLaptop {
    display: none;
  }

  .menuButton {
    display: flex;
    align-self: center;
    margin-right: 10px;
  }

  .buttonsBox {
    position: absolute;
    margin-top: 100px;
    width: 100%;
  }

  .tabs {
    width: 100%;
    overflow: hidden;
    transition: 1s;
    position: absolute;
    top: -48px;
    transform: translateX(100%); /* Initially off-screen to the right */
    opacity: 0;
    visibility: hidden;
  }
  
  .tabs.open {
    transform: translateX(0); /* Slide in from the right */
    opacity: 1;
    visibility: visible;
  }
  
  .tabs.close {
    transform: translateX(100%); /* Slide out to the right */
    opacity: 0;
  }

  .buttons {
    flex-direction: column;
    width: 100%;
    gap: 0px;
    padding: 0px;
    opacity: 0.97;
  }

  .buttons .label {
    text-align: left;
    width: auto;
    padding: 10px;
    border-top: #727171 1px solid;
    border-bottom: #727171 1px solid;
  }


}

/* 添加按压效果 */
.translationButtonLaptop:active,
.translationButtonPhone:active {
  transform: scale(0.95);
}